<template>
  <div>
    <div class="blue box ex2">
      <div class="coral item">
        <div class="pink item">
          <h3>บรอดแคสต์ข้อความ</h3>
        </div>
      </div>
      <div class="coral item">
        <div class="pink item">
          <span class="text-nowrap" @click="$router.push('/BroadcastMessage')"
            >ไปหน้ารายชื่อข้อความ
            <feather-icon
              size="15"
              icon="ChevronRightIcon"
              style="margin-bottom: 5px"
          /></span>
        </div>
      </div>
    </div>
    <br />
    <div class="blue box ex2">
      <div class="coral item">
        <div class="pink item">
          <h5>
            <feather-icon
              size="20"
              icon="RadioIcon"
              style="margin-bottom: 5px; margin-right: 8px"
            />{{ items[0].sendAll_suceess }}/{{ items[0].sendAll }}
          </h5>
        </div>
      </div>
      <div class="coral item">
        <div class="pink item">
          <b-button
            size="sm"
            variant="primary"
            @click="$router.push('/BroadcastMessage/add')"
          >
            <span class="text-nowrap">สร้างใหม่</span>
          </b-button>
        </div>
      </div>
    </div>
    <hr />
    <b-row>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(name)="data">
          <div style="float: left; margin-right: 10px">
            <b-img v-if="data.item.items[0].url"
              :src="data.item.items[0].url"
              width="100px"
              style="
                object-fit: scale-down;
                width: 50px;
                height: 60px;
                border: solid 1px #ccc;
              "
            />
          </div>
          <div style="margin-top: 10px">
            <h6>
              <b>{{ data.item.name }}</b> <br />{{ data.item.date }}
            </h6>
          </div>
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BImg,
  BAlert,
} from "bootstrap-vue";
import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BImg,
    BAlert,
  },
  data() {
    return {
      message_data: "",
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dir: "ltr",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "name", label: "การบรอดแคสต์ที่ผ่านมา" },

        { key: "count_send", label: "จัดส่ง", sortable: true },
        { key: "send_suceess", label: "ส่งแล้ว", sortable: true },
      ],
      items: [],
    };
  },

  mounted() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      const params = {
        // lineoa_id: 28,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("getlist_broadcast_message", params)
        .then((response) => {
          console.log(response.data);
          this.totalRows = response.data.total;
          if(this.totalRows >3){
            for (let i = 0; i < 3; i++) {
              this.items.push(response.data.data[i]) 
            }
          }else{
            this.items = response.data.data;

          }
          // this.message_data = this.items[0].items;
          // for (let i = 0; i < 2; i++) {
          //   this.message_data.push(this.items[0].items[i]) 
          // }
          // console.log("this.message_data  :>> ", this.message_data);

        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ex2 {
  display: flex;
  justify-content: space-between;
}
</style>
