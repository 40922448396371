<template>
  <div>
    <div class="blue box ex2">
      <div class="coral item">
        <div class="pink item">
          <h3>ผู้ใช้งาน Wif-Fi ล่าสุด</h3>
        </div>
      </div>
      <div class="coral item">
        <div class="pink item">
          <!-- <b-button
            size="sm"
            variant="primary"
            @click="$router.push('/BroadcastMessage/add')"
          >
            <span class="text-nowrap">รายชื่อ</span>
          </b-button>nbsp;nbsp; -->
          <b-button
            size="sm"
            variant="outline-primary"
            @click="$router.push('/register/users/line')"
          >
            <span class="text-nowrap">รายชื่อ</span>
          </b-button>
        </div>
      </div>
    </div>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(picture)="data">
        <div @click="detail_data(data.item.id)">
          <b-avatar :src="data.item.picture" size="55px" rounded />
        </div>
      </template>
      <template #cell(name)="data">
        <div @click="detail_data(data.item.id)">
          {{ data.item.displayName || data.item.name }}
        </div>
      </template>
      <template #cell(age)="data">
        <div @click="detail_data(data.item.id)">
          อายุ {{ data.item.age }} ปี
        </div></template
      >
    </b-table>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import api from "@/api";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "picture", label: "Profile" },
        { key: "name", label: "ชื่อ" },
        { key: "adddate", label: "วันที่ใช้งาน" },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          1: "online",
          2: "offline",
        },
        {
          1: "light-success",
          2: "light-danger",
        },
      ],
    };
  },

  mounted() {
    // Set the initial number of items
    this.loaddata();
    // this.totalRows = this.items.length
  },
  methods: {
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("users_register_line", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          // this.items = response.data.data;
          for (let i = 0; i < 3; i++) {
            this.items.push(response.data.data[i]);
          }
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          this.$swal({
            title: "ไม่พบข้อมูล!",
            icon: "error",
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
